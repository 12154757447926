<template>
  <base-page
    class="login-requested"
    centered
  >
    <base-graphic
      class="graphic"
      :image="require('@/assets/images/graphic/email-sent.svg')"
      title="Email Sent"
      body="Check your inbox for new email."
    />
    <base-group
      v-if="showButton"
      align="center"
      spacing="medium"
      direction="vertical"
    >
      <base-button
        label="Link not working?"
        link
        @click="$router.push({ name: 'login' })"
      />
    </base-group>
  </base-page>
</template>

<script>
import { App } from '@capacitor/core';

export default {
  data() {
    return {
      showButton: false,
    };
  },
  created() {
    this.initListeners();
  },
  methods: {
    initListeners() {
      // Listen for App opening / closing
      App.addListener('appStateChange', () => {
        // Show fallback button
        this.showButton = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.graphic {
  margin-bottom: 32px;
}
</style>
